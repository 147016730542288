import axios from "axios";

// export const fetchData = () => {
//   return async (dispatch) => {
//     try {
//       const res = await axios.get("https://ancient-peak-10946.herokuapp.com/api/nbaplayers");
//       res = res.data.filter(player => parseInt(player.first_seas) >= 2010);
//       const len = res.data.length;
//       // const randomID = res.data[Math.floor(Math.random() * len)];
//       // get a random player from the list that has a nba_api_player_id
//       let randomID = null;
// while (!randomID || randomID.nba_api_player_id === null) {
//   randomID = res.data[Math.floor(Math.random() * len)];
// }

// if (randomID && randomID.nba_api_player_id !== null) {
//   // The randomID has a valid non-null nba_api_player_id
//   // console.log('Found a valid player ID:', randomID.nba_api_player_id);
// } else {
//   console.log('Unable to find a player with a valid player ID');
// }

//       // console.log('in actions js');
//       // console.log(randomID.player_id);
//       // console.log(randomID);
//       dispatch(setData(res.data));
//       // dispatch(setAnswerID(randomID.player_id));
//       // dispatch(setAnswerNBAAPIID(randomID.nba_api_player_id));
//       // dispatch(setAnswerName(randomID.fullName));
//     } catch (error) {
//       // Handle error case
//     }
//   };
// };


// this just gives players from 2010 onwards, will have to adapt it to work with the other years
// export const fetchData = () => {
//   return async (dispatch) => {
//     try {
//       const res = await axios.get("https://ancient-peak-10946.herokuapp.com/api/nbaplayers");
//       const playersSince2010 = res.data.filter(player => parseInt(player.first_seas) >= 2010);
      
//       if (playersSince2010.length === 0) {
//         console.log('No players found who started playing since 2010');
//         return; // Exit the function if no valid player is found
//       }

//       const randomIndex = Math.floor(Math.random() * playersSince2010.length);
//       const randomPlayer = playersSince2010[randomIndex];

//       // console.log('Found a valid player ID:', randomPlayer.nba_api_player_id);
//       // console.log(randomPlayer.player_id);
//       // console.log(randomPlayer.fullName);
      
//       dispatch(setData(playersSince2010));
//       // dispatch(setAnswerID(randomPlayer.player_id));
//       // dispatch(setAnswerNBAAPIID(randomPlayer.nba_api_player_id));
//       // dispatch(setAnswerName(randomPlayer.fullName));
//     } catch (error) {
//       // Handle error case
//     }
//   };
// };
export const fetchData = () => {
  return async (dispatch) => {
    try {
      const res = await axios.get("https://ancient-peak-10946.herokuapp.com/api/nbaplayers");
      const playersSince2010 = res.data.filter(player => parseInt(player.first_seas) >= 1900);
      
      if (playersSince2010.length === 0) {
        console.log('No players found who started playing since 2010');
        return; // Exit the function if no valid player is found
      }

      const randomIndex = Math.floor(Math.random() * playersSince2010.length);
      const randomPlayer = playersSince2010[randomIndex];

      // console.log('Found a valid player ID:', randomPlayer.nba_api_player_id);
      // console.log(randomPlayer.player_id);
      // console.log(randomPlayer.fullName);
      
      dispatch(setData(playersSince2010));
      // dispatch(setAnswerID(randomPlayer.player_id));
      // dispatch(setAnswerNBAAPIID(randomPlayer.nba_api_player_id));
      // dispatch(setAnswerName(randomPlayer.fullName));
    } catch (error) {
      // Handle error case
    }
  };
};

export const fetchPlayerData = (url) => {
  return async (dispatch) => {
    try {
      const res = await axios.get(url);
      // console.log('in actions js POLAUYER DATA');
      // console.log(url);
      // console.log(res.data);
      dispatch(setStatData(res.data));
    } catch (error) {
      // Handle error case
    }
  };
};

export const fetchPlayerInfoData = (url) => {
  return async (dispatch) => {
    try {
      const res = await axios.get(url);
      // console.log('in actions js Player Info DATA');
      // console.log(url);
      // console.log(res.data);
      dispatch(setPlayerData(res.data));
    } catch (error) {
      // Handle error case
    }
  };
};

export const postRecordedGuess = (player_id, numOfGuesses) => {
  return async (dispatch) => {
    try {
      const response = await axios.post('https://ancient-peak-10946.herokuapp.com/api/recordedguesses', {
        player_id,
        numOfGuesses,
      });

      console.log(response.data); // Assuming your backend returns the newly created recorded guess

      // Dispatch any actions you need after posting the recorded guess
    } catch (error) {
      console.error('Error posting recorded guess:', error);
    }
  };
};

export const getRecordedGuesses = (player_id) => {
  return async (dispatch) => {
    try {
      const response = await axios.get(`https://ancient-peak-10946.herokuapp.com/api/recordedguesses/${player_id}`);
      const recordedGuesses = response.data;
      console.log('getRecordedGuesses');
      console.log(recordedGuesses);
      // Dispatch any actions you need after fetching recorded guesses
      dispatch(setRecordedGuesses(recordedGuesses));
    } catch (error) {
      console.error('Error fetching recorded guesses:', error);
    }
  };
};

export const setRecordedGuesses = (recordedGuesses) => ({
  type: 'SET_RECORDED_GUESSES',
  payload: {
    recordedGuesses,
  },
});


export const setData = (data) => ({
  type: "SET_DATA",
  payload: {
    data,
  },
});
export const setStatData = (data) => ({
  type: "SET_STATDATA",
  payload: {
    data,
  },
});
export const setPlayerData = (data) => ({
  type: "SET_PLAYERDATA",
  payload: {
    data,
  },
});
// export const setAnswerID = (player_id) => ({
//   type: "SET_ANSWERID",
//   payload: {
//     player_id,
//   },
// });
// export const setAnswerNBAAPIID = (nba_api_player_id) => ({
//   type: "SET_ANSWERNBAAPIID",
//   payload: {
//     nba_api_player_id,
//   },
// });
// export const setAnswerName = (fullName) => ({
//   type: "SET_ANSWERNAME",
//   payload: {
//     fullName,
//   },
// });
// Add more action creators if needed
export const setLoading = (loading) => ({
  type: "SET_LOADING",
  payload: loading,
});

export const setError = (error) => ({
  type: "SET_ERROR",
  payload: error,
});