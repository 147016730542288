// import React from 'react';
// import ReactDOM from 'react-dom/client';
// import { BrowserRouter, Routes, Route } from "react-router-dom";
// import './index.css';
// import App from './App';
// import Home from "./pages/Home";
// import Layout from "./pages/Layout";
// import NBA from "./pages/NBA";
// import NFL from "./pages/NFL";
// import MLB from "./pages/MLB";
// import Blogs from "./pages/Blogs";
// import Contact from "./pages/Contact";
// import NoPage from "./pages/NoPage";
// import reportWebVitals from './reportWebVitals';

// const root = ReactDOM.createRoot(document.getElementById('root'));
// root.render(
//   <React.StrictMode>
//     <BrowserRouter>
//     <App />
//       <Routes>
      
//         <Route path="/" element={<Layout />}>
//           <Route index element={<Home />} />
//           <Route path="blogs" element={<Blogs />} />
//           <Route path="nba" element={<NBA />} />
//           <Route path="nfl" element={<NFL />} />
//           <Route path="mlb" element={<MLB />} />
//           <Route path="contact" element={<Contact />} />
//           <Route path="*" element={<NoPage />} />
//         </Route>
//       </Routes>
     
//     </BrowserRouter>
   
//   </React.StrictMode>
// );

// // If you want to start measuring performance in your app, pass a function
// // to log results (for example: reportWebVitals(console.log))
// // or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();

// import React from "react";
// import ReactDOM from "react-dom";
// import { createRoot } from 'react-dom/client';
// import App from "./App";


// // ReactDOM.render(
// //   <React.StrictMode>
// //         <App />
// //   </React.StrictMode>,
// //   document.getElementById("root")
// // );
// createRoot(document.getElementById('root')).render(<App />);

import React from 'react'
import ReactDOM from 'react-dom/client'
import App from './App'
import store from './app/store'
import Modal from 'react-modal';
import { Provider } from 'react-redux'

// As of React 18
Modal.setAppElement('#root');
const root = ReactDOM.createRoot(document.getElementById('root'))

root.render(
  <Provider store={store}>
    <App />
  </Provider>
)