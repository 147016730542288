// recordedGuessesReducer.js

const initialState = [];

const recordedGuessesReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'SET_RECORDED_GUESSES':
      return action.payload.recordedGuesses;
    default:
      return state;
  }
};

export default recordedGuessesReducer;
