import Home from "./pages/Home";
import NBA from "./pages/NBA";
// import NFL from "./pages/NFL";
// import MLB from "./pages/MLB";
// import Signup from "./pages/Signup";
// import Login from "./pages/Login";
// import Profile from "./pages/Profile";
import MOVIES from "./pages/MOVIES";
import Layout from "./pages/Layout";
// import { useState } from 'react';
import { BrowserRouter, Routes, Route } from "react-router-dom";


function App() {

 
  

  return (
    <div >
      <BrowserRouter>
        <Routes>
          <Route path="/">
           
            <Route
              index
              element={
                  <Home />
              }
            />
              <Route
              path='NBA'
                element={
                    <NBA />
                }
              />
              {/* <Route
                path="NFL"
                element={
                    <NFL />
                }
              />
              <Route
                path="MLB"
                element={
                    <MLB />
                }
              /> */}
              <Route
                path="MOVIES"
                element={
                    <MOVIES />
                }
              />
              {/* <Route
                path="Signup"
                element={
                    <Signup />
                }
              />
               <Route
                path="Login"
                element={
                    <Login />
                }
              />
               <Route
                path="Profile"
                element={
                    <Profile />
                }
              /> */}
              <Route
                path="Layout"
                element={
                    <Layout />
                }
              />
            
          </Route>
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
// import nba from './imgs/nba.png';
// import nfl from './imgs/nfl.png';
// import mlb from './imgs/mlb.png';
// import { Link } from "react-router-dom";
// import './App.css';


// function App() {
//   return (
//     <div className="App">
//       <header className="App-header">
//         <h1>Sportle</h1>
//         <h2>Pick a Sport</h2>
//         <h3>Next Sportle in: timer</h3>
//       <div className='selectionContainer'>
//         <div className='imageLabels'>
       
//         <Link to="nba" >
//            <img className='formImg' src={nba} alt="NBA"/>
//         </Link>
//         </div>
//         <div className='imageLabels'> 
//         <Link to="nfl" >
//           <img className='formImg' src={nfl} alt="NFL"/>
//           </Link>
//         </div>
//         <div className='imageLabels'>
//         <Link to="mlb" >
//           <img className='formImg' src={mlb} alt="MLB"/>
//           </Link>
//         </div>
//         </div>
  
       
//       </header>
//     </div>
//   );
// }

// export default App;
