import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getRecordedGuesses } from "../app/reducers/actions";
import { Bar } from 'react-chartjs-2';
import { Chart, registerables } from 'chart.js';
Chart.register(...registerables);
const GuessDistributionModal = ({ answerID }) => {
  const dispatch = useDispatch();
  const [chartData, setChartData] = useState(null);
  const recordedGuesses = useSelector((state) => state.recordedGuesses);

  useEffect(() => {
    const fetchGuesses = async () => {
      try {
        await dispatch(getRecordedGuesses(answerID));
      } catch (error) {
        console.error('Error fetching recorded guesses:', error);
      }
    };

    fetchGuesses();
  }, [dispatch, answerID]);

  useEffect(() => {
    console.log('Recorded guesses updated:', recordedGuesses);

    if (recordedGuesses && recordedGuesses.length > 0) {
      const guessValues = recordedGuesses.map((guess) => guess.numOfGuesses);


      setChartData({
        labels: Array.from({ length: guessValues.length }, (_, i) => `Attempt ${i + 1}`),
        datasets: [
          {
            label: 'Number of Guesses',
            backgroundColor: 'rgba(75,192,192,0.4)',
            borderColor: 'rgba(75,192,192,1)',
            borderWidth: 1,
            hoverBackgroundColor: 'rgba(75,192,192,0.6)',
            hoverBorderColor: 'rgba(75,192,192,1)',
            data: guessValues,
          },
        ],
      });
    } else {
    }
  }, [recordedGuesses]);


  return (
    <div className='chartDiv'>
      {chartData ? (
        <>
          <h2>User Statistics</h2>
          <Bar data={chartData} options={{ scales: { x: { type: 'category' } } }} />
        </>
      ) : (
        <p>Loading...</p>
      )}
    </div>
  );
};

export default GuessDistributionModal;
