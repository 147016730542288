// index.js

import { combineReducers } from 'redux';
import nbaDataReducer from './nbaDataReducer';
import nbaPlayerDataReducer from './nbaPlayerDataReducer';
import nbaPlayerInfoDataReducer from './nbaPlayerInfoDataReducer';
import guessesReducer from './guessesReducer';
import recordedGuessesReducer from './recordedGuessesReducer'; // Import your new reducer

const rootReducer = combineReducers({
  nbaData: nbaDataReducer,
  nbaPlayerData: nbaPlayerDataReducer,
  nbaPlayerInfoData: nbaPlayerInfoDataReducer,
  guesses: guessesReducer,
  recordedGuesses: recordedGuessesReducer, // Add your new reducer here
});

export default rootReducer;
