import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Link } from "react-router-dom";
import TextField from '@mui/material/TextField';
import Skeleton from '@mui/material/Skeleton';
import Stack from '@mui/material/Stack';
import Box from "@mui/material/Box";
import Paper from '@mui/material/Paper';
import CircularProgress from '@mui/material/CircularProgress';
import ResponsiveAppBar from '../components/ResponsiveAppBar';
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';
import Button from '@mui/material/Button';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import '../App.css';

const MOVIESLISt = () => {
  const [movies, setMovies] = useState([]);
  const filterOptions = createFilterOptions({
    matchFrom: 'any',
    limit: 80,
  });
  // const years = [2022, 2021, 2020, 2019, 2018, 2017, 2016, 2015, 2014, 2013];

  // const [movieYear, setMovieYear] = useState(2022);
  // const randomIndex = Math.floor(Math.random() * years.length);
  // const randomNumber = years[randomIndex];


  const [numOfGuesses, setNumOfGuesses] = useState(5);
  const [data, setData] = useState({});
  const [guessPlayer, setGuessPlayer] = useState({});
  const [guesses, setGuesses] = useState([]);
  const [disabled, setDisabled] = useState(false);
  const [showAnswer, setShowAnswer] = useState(false);
  const [showRightWrong, setShowRightWrong] = useState(false);
  const [answerID, setAnswerID] = useState(0);
  const [answerName, setAnswerName] = useState(0);
  const [randomID, setRandomID] = useState({});
  const [genres, setGenres] = useState([]);
  const [cast, setCast] = useState([]);
  const [director, setDirector] = useState('');
  const [writer, setWriter] = useState('');
  const [loading, setLoading] = useState(true);
  const [open, setOpen] = useState(false);
  const [movieScore, setMovieScore] = useState(() => {
    // Check if the variable exists in localStorage
    const storedMovieScore = localStorage.getItem('movieScore');
    return storedMovieScore ? parseInt(storedMovieScore) : 0;
  });
  const [movieHighScore, setMovieHighScore] = useState(() => {
    // Check if the variable exists in localStorage
    const storedMovieHighScore = localStorage.getItem('movieHighScore');
    return storedMovieHighScore ? parseInt(storedMovieHighScore) : 0;
  });
  const handleOpen = () => {
    setOpen(true);
  };
  
  const handleClose = () => {
    setOpen(false);
  };
  useEffect(() => {
    // Update the stored score whenever it changes
    localStorage.setItem('movieScore', movieScore.toString());
  }, [movieScore]);
  useEffect(() => {
    // Update the stored highScore whenever it changes
    localStorage.setItem('movieHighScore', movieHighScore.toString());
  }, [movieHighScore]);
  const submit = e => {
    e.preventDefault()
    setNumOfGuesses(numOfGuesses - 1);
    // console.log(numOfGuesses);
    // console.log(guessPlayer);
    // console.log(answerID);

    // if (guessPlayer.name.toUpperCase() === Answer) {
    //   setShowRightWrong(true);
    //   setDisabled(true);
    // }
    if (guessPlayer.id === answerID) {  

      setShowRightWrong(true);
      setDisabled(true);
      setNumOfGuesses(0);
      setMovieScore(movieScore + 1);
      // console.log(movieScore);
    }
    if (numOfGuesses === 1) {
      // console.log('numOfGuesses is over');
      // console.log(numOfGuesses);
      setDisabled(true);
      setShowAnswer(true);
      if (movieScore > movieHighScore) {
        setMovieHighScore(movieScore);
      }
      setMovieScore(0);
      // console.log(movieScore);
    }
    
    setGuessPlayer('');

}
const resetGuesses = () => {
  setNumOfGuesses(0);
  setDisabled(true);
  setShowAnswer(true);
  if (movieScore > movieHighScore) {
    setMovieHighScore(movieScore);
  }

  setMovieScore(0);
  handleClose();
  // console.log(movieScore);
};
const refreshPage = () => {
  window.location.reload();
};
const handleChange = (event, value) => {
  // console.log(value);
  if (value !== undefined && value !== null) {
    setGuessPlayer(value);
  // console.log(guessPlayer);
  }
}
  useEffect(() => {
    const fetchMovies = async () => {
      try {
        
        const years = [2022, 2021, 2020, 2019, 2018, 2017, 2016, 2015, 2014, 2013];
       
  const randomIndex = Math.floor(Math.random() * years.length);
  const randomNumber = years[randomIndex];
        // console.log('movieYear');
        // console.log(randomNumber);
        // const response = await axios.get(
        //   `https://api.themoviedb.org/3/discover/movie?include_adult=true&include_video=true&language=en-US&page=1&sort_by=popularity.desc&year=${movieYear}&api_key=640f53322b6cf1148f7dedd4a05535c4`
        // );
        // setMovies(response.data.results);

        const requests = [];

      for (let page = 1; page <= 5; page++) {
        requests.push(fetch(`https://api.themoviedb.org/3/discover/movie?include_adult=false&include_video=true&language=en-US&page=${page}&sort_by=popularity.desc&year=${randomNumber}&api_key=640f53322b6cf1148f7dedd4a05535c4`));
      }
      const responses = await Promise.all(requests);
      const moviesData = await Promise.all(responses.map(response => response.json()));
      const allMovies = movies.concat(...moviesData.map(data => data.results));
      setMovies(allMovies);
        const len = allMovies.length;
        // setRandomID(response.data.results[Math.floor(Math.random() * len)])
const choosenID = allMovies[Math.floor(Math.random() * len)];

setRandomID(choosenID)
// console.log('array id');
// console.log(randomID);
const activePlayer =  randomID;
setAnswerID(choosenID.id);
// console.log('answerID');
// console.log(answerID);
setAnswerName(choosenID.title);
// console.log('answerName');
// console.log(answerName);

setData(allMovies);
        // console.log('allMovies');
        // console.log(allMovies);

        const genreResponse = await axios.get(
          'https://api.themoviedb.org/3/genre/movie/list',
          {
            params: {
              api_key: '640f53322b6cf1148f7dedd4a05535c4',
            },
          }
        );
        setGenres(genreResponse.data.genres);
        
        const responseCast = await axios.get(
          `https://api.themoviedb.org/3/movie/${choosenID.id}`,
          {
            params: {
              api_key: '640f53322b6cf1148f7dedd4a05535c4',
              append_to_response: 'credits', // Include cast information in the response
            },
          }
        );
        const movieDirector = responseCast.data.credits.crew.find(person => person.job === 'Director');
const movieWriter = responseCast.data.credits.crew.find(person => person.job === 'Writer' || person.job === 'Screenplay');

setDirector(movieDirector ? movieDirector : 'Director information not found');
setWriter(movieWriter ? movieWriter : 'Writer information not found');
        setCast(responseCast.data);
          // console.log('responseCast.data');
          // console.log(responseCast.data);
        

      } catch (error) {
        console.error(error);
      }
     
      
    };

    fetchMovies();
  }, []);

  if (!randomID || genres.length === 0) {
    return (
      <div className="App">
      <header className="App-header">
        {/* <div className="TopHeader">
        <Link to="/" className = 'SportHome'><h1 className="selectedPageTitle">Sportle</h1></Link>
        </div> */}
        <ResponsiveAppBar />
        <h2>Movies</h2>
        <Box className="paddingBox" sx={{ display: 'flex' }}><CircularProgress /></Box>
        </header>
        </div>

        );
  }

  
  const actors = cast?.credits?.cast || [];
  const crews = cast?.credits?.crew || [];
  


  const production = cast?.production_companies || [];
  const genreNames = randomID.genre_ids.map((genreId) => {
    const genre = genres.find((genre) => genre.id === genreId);
    return genre ? genre.name : '';
  });

  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
  
    // These options are needed to round to whole numbers if that's what you want.
    //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
    //maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
  });
  
  const paperStyle = {
    backgroundColor: '#9e9e9e',
    color: '#000000', // Text color can be set to white or any other color that contrasts with the background
    padding: '1rem',
    maxWidth:'700px',
    marginTop:'0px',
  };
    const paperStyle2 = {
    backgroundColor: '#9e9e9e',
    color: '#000000', // Text color can be set to white or any other color that contrasts with the background
    padding: '1rem',
    maxWidth:'500px',
    marginTop:'0px',
  };
  const paperStyle3 = {
    backgroundColor: '#9e9e9e',
    color: '#000000', // Text color can be set to white or any other color that contrasts with the background
    padding: '1rem',
    maxWidth:'900px',
    marginTop:'0px',
  };
  
  // console.log('array id');
  // console.log(randomID);
  // console.log('answerID');
  // console.log(answerID);
  // console.log('answerName');
  // console.log(answerName);

  return (
    <div className="App">
      <header className="App-header">
        {/* <div className="TopHeader">
        <Link to="/" className = 'SportHome'><h1 className="selectedPageTitle">Sportle</h1></Link>
        </div> */}
        <ResponsiveAppBar />
      <h2 className="NBATITLE">Movies</h2>
      <span className="scoreBoard">High Score: {movieHighScore} | Score: {movieScore}</span>
      {randomID === 0 ?
      <Stack spacing={1}>
      {/* For variant="text", adjust the height via font-size */}
      <Skeleton variant="text" sx={{ fontSize: '1rem' }} />
      {/* For other variants, adjust the size with `width` and `height` */}
      <Skeleton variant="circular" width={40} height={40} />
      <Skeleton variant="rectangular" width={210} height={60} />
      <Skeleton variant="rounded" width={210} height={60} />
    </Stack>
    : <span> </span>}
      
        {/* <ul>
        {movies.map((movie) => (
          <li key={movie.id}>{movie.title}</li>
        ))}
      </ul>  */}
      <div className='movieActor'>
       <Paper style={paperStyle} elevation={3}>
        <div className='movieInfo'>
          <div className='moviePoster'>
            <img className={`posterImg${numOfGuesses}`}
            src={`https://image.tmdb.org/t/p/w500${randomID.backdrop_path}`}
            alt='Movie Title'
          />

      </div>
      <div className='movieDetails'>
        {randomID !== 0 ? <p className='releaseP'>Release Date: {randomID.release_date}</p> : ''}
        {randomID !== 0 ? <p className='releaseP'>Rating: {randomID.vote_average}/10</p> : ''}
        <p className='genreP'>Genres: {genreNames.join(', ')}</p>
        {cast.budget !== 0 ? <p className='overview'>Budget: <span  className={numOfGuesses > 5 ? "blurredText" : ""}>{formatter.format(cast.budget)}</span></p> : ''}
        {cast.revenue !== 0 ? <p className='overview'>Revenue: <span  className={numOfGuesses > 5 ? "blurredText" : ""}>{formatter.format(cast.revenue)}</span></p> : ''}
        {cast.tagline !== '' ? <p className='overview'>Tagline: <span  className={numOfGuesses > 4 ? "blurredText" : ""}>{cast.tagline}</span></p> : ''}

      </div>
    </div>
    <p className='descText'>Description: <span  className={numOfGuesses > 3 ? "blurredText" : ""}>{randomID.overview}</span></p> 

    </Paper>

    <Paper style={paperStyle2} elevation={3}>
    <h3 className='actorTitle'>Actors</h3>
   <div className='actorDiv'>
        {actors.slice(0, 5).map((actor) => (
          <div key={actor.id}>
          <p className={numOfGuesses > 1 ? "blurredText actorName" : "actorName"}>{actor.name}</p>
          <div className='actorImgContainer'>
              <img  className={numOfGuesses > 1 ? "blurredImg" : ""}
              src={`https://image.tmdb.org/t/p/w500${actor.profile_path}`}
              alt={actor.name}
            />
          </div>
        </div>
        ))}
      </div> 
    </Paper>
    {production.length !== 0 ? <Paper style={paperStyle3} elevation={3}>
    
      <>
      
      <h4 className='ProductionCompanyTitle'>Production Companies</h4>
      <div className='actorDiv'>
        {production.slice(0, 5).map((prod) => (
          <div key={prod.id}>
          {/* <li >{prod.name}</li> */}
          <img  className={numOfGuesses > 4 ? "blurredImg circular-image-prod" : "circular-image-prod"}
          src={`https://image.tmdb.org/t/p/w500${prod.logo_path}`}
          alt={prod.name} 
        />
        </div>
        ))}
      </div></>
       
      

    </Paper>: <span></span>}

    <Paper style={paperStyle2} elevation={3}>
    <h3 className='actorTitle'>Crew</h3>
   <div className='actorDiv'>
 
    {/* {crews.job['Director']} */}
    <div key={director.id}>
        <p className={numOfGuesses > 2 ? "blurredText actorName" : "actorName"}>{director.name}</p>
        <p className={numOfGuesses > 2 ? "blurredText JobP" : "JobP"}>{director.job}</p>
        <div className='actorImgContainer'>
          <img
            className={numOfGuesses > 2 ? "blurredImg" : ""}
            src={`https://image.tmdb.org/t/p/w500${director.profile_path}`}
            alt={director.name}
          />
        </div>
      </div>
      <div key={writer.id}>
        <p className={numOfGuesses > 2 ? "blurredText actorName" : "actorName"}>{writer.name}</p>
        <p className={numOfGuesses > 2 ? "blurredText JobP" : "JobP"}>{writer.job}</p>
        <div className='actorImgContainer'>
          <img
            className={numOfGuesses > 2 ? "blurredImg" : ""}
            src={`https://image.tmdb.org/t/p/w500${writer.profile_path}`}
            alt={writer.name}
          />
        </div>
      </div>
   {/* {crews.map((crew) => (
    (crew.job === 'Director' || crew.job === 'Writer' || crew.job === 'Story' || crew.job === "Cinematography") ? (
      <div key={crew.id}>
        <p className={numOfGuesses > 2 ? "blurredText actorName" : "actorName"}>{crew.name}</p>
        <p className={numOfGuesses > 2 ? "blurredText JobP" : "JobP"}>{crew.job}</p>
        <div className='actorImgContainer'>
          <img
            className={numOfGuesses > 2 ? "blurredImg" : ""}
            src={`https://image.tmdb.org/t/p/w500${crew.profile_path}`}
            alt={crew.name}
          />
        </div>
      </div>
    ) : ''
  ))} */}

      </div> 
    </Paper>
    </div>
      {/* <p>Year: {randomID.release_date.length > 0 ? randomID.release_date.substring(0, 4) : ""}</p> */}
      {/* <img className={`posterImg${numOfGuesses}`}
        src={`https://image.tmdb.org/t/p/w500${randomID.poster_path}`}
        alt='Movie Title'
      /> */}

      

      <h3 className={showRightWrong ? "showAnswer" : "hideAnswer"}>Congratulations! You got it</h3>
        <h3 className={!showRightWrong ? "showAnswer" : "hideAnswer"}>Guesses Remaining: {numOfGuesses > 0 ? numOfGuesses : 'Game Over!'}</h3>
      <form onSubmit={submit}>
      <div className="acoutcompleteHolder">
<Autocomplete
      disablePortal
      id="guessPlayer2"
      name="guessPlayer"
      value={guessPlayer}
      filterOptions={filterOptions}
      disabled={disabled}
      options={data}
      getOptionLabel={(option) => option.title ? option.title : ""}
      sx={{ width: 300 }}
  
      isOptionEqualToValue={(option, value) =>
        option.id === value.id && option.title === value.title
      }
      // onChange={(event, value) => setGuessPlayer(event.target.value)}
      onChange={handleChange}
      // onChange={(event, value) => console.log(value.label)}
      renderOption={(props, option) => (
        <Box component="li" {...props} key={option.id}>
          {option.title} 
        </Box>
      )}
      renderInput={(params) => <TextField {...params} label="Guess Movie"
      // getOptionSelected={(option) => option.title === 'dull'}
      
      // onChange={e => setGuessPlayer(e.target.value)}
      />}
    />
    </div>
    <button disabled={!guessPlayer.title} className={!disabled ? "showButton" : "hideButton"} onClick={() => {
        setGuesses([
          ...guesses,
          { id: guessPlayer.id, guess: guessPlayer.title }
        ]);
      }}>Guess</button><br></br>
{/* <button disabled={disabled} className="otherButtons redButton" onClick={resetGuesses}>Give Up?</button> */}
      {/* <br /> */}

   
    </form>

    <div>
      <Button disabled={disabled} className="otherButtons redButton" variant="contained"  onClick={handleOpen}>
        Give up?
      </Button>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="give-up-modal"
        aria-describedby="give-up-modal-description"
      >
        <Fade in={open}>
          <div
            style={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              backgroundColor: '#fff',
              padding: '2rem',
              outline: 'none',
              borderRadius: '8px',
            }}
          >
            <h2 id="give-up-modal">Are you sure?</h2>
            <p id="give-up-modal-description">
              Do you really want to give up dork?
            </p>
            <Button variant="contained" className='redButton' onClick={resetGuesses}>
              Give Up
            </Button>
            <Button variant="contained" className='greyButton' onClick={handleClose}>
              Cancel
            </Button>
          </div>
        </Fade>
      </Modal>
    </div>
    <ul className="guessList">
        {guesses.map(guesses => (
           <>
          {/* <script>console.log(guesses.id);</script> */}
          <li key={guesses.id}>{guesses.guess} {guesses.id === answerID ? '✅': '❌'} </li>
          </>
        ))}
      </ul> 
      <p className={showRightWrong ? "showAnswer" : "hideAnswer"}>Correct Answer </p>
      <p className={showAnswer ? "showAnswer" : "hideAnswer"}>Answer: {answerName}</p>
      <button className={showRightWrong || showAnswer ? "showAnswer otherButtons blueButton" : "hideAnswer otherButtons blueButton"} onClick={refreshPage}>Play Again?</button>
      </header>
    </div>
  );
}

export default MOVIESLISt;