const initialState = {
  statData: [],
  loading: false,
  error: false,
};

export default function nbaPlayerDataReducer(state = initialState, action) {
  switch (action.type) {
    case "SET_STATDATA":
      return {
        ...state,
        statData: action.payload.data,
      };
    default:
      return state;
  }
}
