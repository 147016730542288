// import { Outlet } from "react-router-dom";
import '../layout.css';
import React, { useState } from 'react';
import louovo from '../imgs/louovo.PNG';
import { makeStyles } from '@material-ui/core/styles';
import { format } from 'date-fns';
import { Box, Link, IconButton } from '@material-ui/core';
import { Facebook, Twitter, Instagram, YouTube } from '@material-ui/icons';
import cookie from '../imgs/cookie.PNG';
import { TextField, Button} from '@material-ui/core';
import { DatePicker } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns'; // Import the date utility library (e.g., date-fns)
import Icon from "../components/mySvg";
import IconBottom from "../components/mySvgBottom";
import { MuiPickersUtilsProvider } from '@material-ui/pickers'; // To use the date utility library with Material UI, wrap your component with the MuiPickersUtilsProvider higher-order component

const useStyles = makeStyles((theme) => ({
  footer: {
    backgroundColor: '#5d3917',
    padding: theme.spacing(2),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  linksRow: {
    marginBottom: theme.spacing(1),
    '& > *': {
      margin: theme.spacing(1),
    },
  },
  socialIconsRow: {
    '& > *': {
      margin: theme.spacing(1),
    },
  },
  copyright: {
    marginBottom: theme.spacing(1),
    fontSize: '0.8rem',
    color: '#888',
  },
}));

const Layout = () => {
  const [email, setEmail] = useState('');
  const [birthday, setBirthday] = useState(null);

  const handleSubmit = (e) => {
    e.preventDefault();
    // Handle form submission logic here
    console.log('Email:', email);
    console.log('Birthday:', birthday);
    // You can perform further actions like sending data to a server
  };
  const classes = useStyles();
  return (
    
     <div className="App">
      <header className="App-headerLayout">
        <div className="TopHeaderLayout">
          <div className='navBar'>
          <Link to="/" className = 'Sport'>ABOUT</Link>
          <Link to="/nba" className = 'Sport'>PRODUCTS</Link>
          <img className='logoImage' src={louovo} alt="louovo"/>
          <Link to="/nfl" className = 'Sport'>PROCESS</Link>
          <Link to="/mlb" className = 'Sport'>FIND US</Link>
          </div>
        </div>
        
      <div className='layoutContainer'>
      <Icon />
      {/* <img className='backgroundImage' src={cookies} alt="cookies"/> */}
      <IconBottom />

      </div>
      <div className='layoutTextContainer'>
        <p>In addition to their classic recipes, we pride ourselves on innovation.
Our team of talented pastry chefs constantly pushes the boundaries,
experimenting with unique flavor combinations and modern twists on
traditional favorites.</p>
      </div>
      <div className='LayoutFindTreat'>

      
        <h2>Find Your Treat</h2>
        
      </div>
      <div className='layoutDarkContainer'>
      <Icon />
        <div className='layoutImgContainer'>
          <div className="layoutImg">
            <img className='cookiesImage' src={cookie} alt="cookies"/>
            <div className="text-overlay">
            <p>COOKIES</p>
            </div>
          </div>
          <div className="layoutImg">
          <img className='cookiesImage' src={cookie} alt="cookies"/>
          <div className="text-overlay">
          <p>CAKE</p>
          </div>
          </div>
          <div className="layoutImg">
            <img className='cookiesImage' src={cookie} alt="cookies"/>
            <div className="text-overlay">
            <p>PASTRIES</p>
            </div>
          </div>
        </div>
      </div>
      <div className='sepLayout'>

      </div>
      <div className='layoutFormContainer'>
          <h3>Keep Up to Date</h3>
          <h4>Subscribe to our newsletter</h4>
<p>for updates on new recipe ideas, deals, and
exciting updates!</p>
      <form onSubmit={handleSubmit}>
        <div className='layoutFormForm'>
           <div className='layoutFormInput'>
            <TextField
              label="Enter your email"
              variant="standard"
              fullWidth
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              InputProps={{
                disableUnderline: true, // <== added this
              }}
            />
        </div>
        
        <div className="layoutFormDate">

<MuiPickersUtilsProvider utils={DateFnsUtils} dateLibInstance={format}> {/* Wrap your component tree with MuiPickersUtilsProvider */}

            <DatePicker
              label="MM / DD / YYYY*"
              inputVariant="standard"
              format="MM/dd/yyyy"
              fullWidth
              value={birthday}
              onChange={(date) => setBirthday(date)}
              InputProps={{
                disableUnderline: true, // <== added this
              }}
            />
    </MuiPickersUtilsProvider>

        </div>
        </div>
        <Button type="submit" variant="contained" style={{ backgroundColor: "#5d3917", color: "#ffffff" }}>
          Join
        </Button>
        <small>* add your birthday for a special surprise</small>
      </form>
      </div>

      <div className='layoutFooter'>
      <Icon />
      <footer className='footerContainer'>
      <Box className={classes.linksRow}>
        <Link href="#">About</Link>
        <Link href="#">FAQ</Link>
        <Link href="#">Contact Us</Link>
        <Link href="#">Careers</Link>
        <Link href="#">Press</Link>
      </Box>
      <Box className={classes.socialIconsRow} >
        <IconButton href="#" aria-label="Facebook">
          <Facebook />
        </IconButton>
        <IconButton href="#" aria-label="Twitter">
          <Twitter />
        </IconButton>
        <IconButton href="#" aria-label="Instagram">
          <Instagram />
        </IconButton>
        <IconButton href="#" aria-label="YouTube">
          <YouTube />
        </IconButton>
      </Box>
      <div className={classes.copyright}>
        &copy; {new Date().getFullYear()} Marc Cortez. All rights reserved.
      </div>
    </footer>
      </div>

      </header>

      
    </div>

  )
};

export default Layout;