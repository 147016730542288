import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import LinearProgress from '@mui/material/LinearProgress';
// import PlayerInfo from "../components/NBAPlayerInfo";
import Paper from '@mui/material/Paper';
import { useEffect } from 'react';
// import useFetch from "../hooks/useFetch2";
import '../App.css';
import { useDispatch, useSelector } from "react-redux";
import { fetchPlayerData } from "../app/reducers/actions";
import { sortNBAData } from './sortTable'; 

const BasicTable = (props) => {
//   const [arr, setarr] = useState({});
// console.log('parent to child');
// console.log(props);
// const { data, loading, error } = useFetch(`/nbaplayerstats/${props.parentToChild}`);
// console.log('player stats data');


 
const dispatch = useDispatch();
const { statData, loading } = useSelector(
  (state) => state.nbaPlayerData
);

useEffect(() => {
  dispatch(fetchPlayerData(`https://ancient-peak-10946.herokuapp.com/api/nbaplayerstats/${props.parentToChild}`));
}, [dispatch, props.parentToChild]);



// console.log(statData);
const sortedStatData = sortNBAData(statData);
  return (
    <>
    {/* <PlayerInfo parentToChild={props.parentToChild} numGuesses={props.numGuesses}/> */}
    <TableContainer component={Paper} sx={{ maxWidth: 1400 }}>
      {/* {parentToChild} */}
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
          <TableRow>
              <TableCell align="center" colSpan='29' >
                Regular Season Per Game Stats
              </TableCell>
            
            </TableRow>
          <TableRow>
            <TableCell>Season</TableCell>
            <TableCell align="right">Age</TableCell>
            <TableCell align="right">TM</TableCell>
            {/* <TableCell align="right">Pos</TableCell> */}
            <TableCell align="right">G</TableCell>
            <TableCell align="right">GS</TableCell>
            <TableCell align="right">POS</TableCell>
            <TableCell align="right">MP</TableCell>
            {/* <TableCell align="right">FG</TableCell> */}
            {/* <TableCell align="right">FGA</TableCell> */}
            <TableCell align="right" className='mobileHide'>FG%</TableCell>
            <TableCell align="right">3P</TableCell>
            <TableCell align="right">3PA</TableCell>
            <TableCell align="right" className='mobileHide'>3P%</TableCell>
            {/* <TableCell align="right">2P</TableCell>
            <TableCell align="right">2PA</TableCell>
            <TableCell align="right">2P%</TableCell> */}
            {/* <TableCell align="right">eFG%</TableCell> */}

            {/* <TableCell align="right">FT</TableCell> */}
            {/* <TableCell align="right">FTA</TableCell> */}
            <TableCell align="right" className='mobileHide'>FT%</TableCell>

            {/* <TableCell align="right">ORB</TableCell> */}
            {/* <TableCell align="right">DRB</TableCell> */}
            <TableCell align="right">REB</TableCell>
            <TableCell align="right">AST</TableCell>
            <TableCell align="right" className='mobileHide'>STL</TableCell>
            <TableCell align="right" className='mobileHide'>BLK</TableCell>
            {/* <TableCell align="right">TOV</TableCell> */}

            <TableCell align="right">PTS</TableCell>
          </TableRow>
        </TableHead>
        
        <TableBody>
        {sortedStatData.length === undefined && loading ? (
  <TableRow>
    <TableCell colSpan={29}>
      <LinearProgress />
    </TableCell>
  </TableRow>
) : (
        Object.values(sortedStatData).map((value, index) => 
          // {arr.map((year) => (
            <TableRow
              key={index}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell component="th" scope="key">
                {value.season}
              </TableCell>
              <TableCell align="right">{value.player_age}</TableCell>
              <TableCell align="right">{value.team_abbreviation}</TableCell>
              {/* <TableCell align="right">{value.PLAYER_ID}</TableCell> */}
              <TableCell align="right">{value.gp}</TableCell>
              <TableCell align="right">{value.gs}</TableCell>
              <TableCell align="right">{value.pos}</TableCell>
              {/* <TableCell align="right">{value.min}</TableCell>
              <TableCell align="right">{value.fgm}</TableCell>
              <TableCell align="right">{value.fga}</TableCell>
              <TableCell align="right">{value.fg_pct}</TableCell>
              <TableCell align="right">{value.fg3m}</TableCell>
              <TableCell align="right">{value.fg3a}</TableCell>
              <TableCell align="right">{value.fg3_pct}</TableCell>
              {/* <TableCell align="right">{key.FTM}</TableCell>
              <TableCell align="right">{key.FTA}</TableCell>
              <TableCell align="right">{key.FT_PCT}</TableCell> */}
              {/* <TableCell align="right">{key.efg}</TableCell> */}

              {/* <TableCell align="right">{value.ftm}</TableCell>
              <TableCell align="right">{value.fta }</TableCell>
              <TableCell align="right">{value.ft_pct}</TableCell>

              <TableCell align="right">{value.oreb}</TableCell>
              <TableCell align="right">{value.dreb}</TableCell>
              <TableCell align="right">{value.trb}</TableCell>
              <TableCell align="right">{value.reb}</TableCell> 
              <TableCell align="right">{value.ast}</TableCell>
              <TableCell align="right">{value.stl}</TableCell>
              <TableCell align="right">{value.blk}</TableCell>
              <TableCell align="right">{value.tov}</TableCell>
              <TableCell align="right">{value.pts}</TableCell> */} 

              <TableCell align="right">{Math.round(((value.min / value.gp) + Number.EPSILON) * 100) / 100}</TableCell>
              {/* <TableCell align="right">{Math.round(((value.fgm / value.gp) + Number.EPSILON) * 100) / 100}</TableCell> */}
              {/* <TableCell align="right">{Math.round(((value.fga / value.gp) + Number.EPSILON) * 100) / 100}</TableCell> */}
              <TableCell align="right" className='mobileHide'>{(value.fg_pct * 100).toFixed(1) + '%'}</TableCell>
              <TableCell align="right">{Math.round(((value.fg3m / value.gp) + Number.EPSILON) * 100) / 100}</TableCell>
              <TableCell align="right">{Math.round(((value.fg3a / value.gp) + Number.EPSILON) * 100) / 100}</TableCell>
              <TableCell align="right" className='mobileHide'>{(value.fg3_pct * 100).toFixed(1) + '%'}</TableCell>
              {/* <TableCell align="right">{key.FTM}</TableCell>
              <TableCell align="right">{key.FTA}</TableCell>
              <TableCell align="right">{key.FT_PCT}</TableCell> */}
              {/* <TableCell align="right">{key.efg}</TableCell> */}

              {/* <TableCell align="right">{Math.round(((value.ftm / value.gp) + Number.EPSILON) * 100) / 100}</TableCell> */}
              {/* <TableCell align="right">{Math.round(((value.fta / value.gp) + Number.EPSILON) * 100) / 100}</TableCell> */}
              <TableCell align="right" className='mobileHide'>{(value.ft_pct * 100).toFixed(1) + '%'}</TableCell>

              {/* <TableCell align="right">{Math.round(((value.oreb / value.gp) + Number.EPSILON) * 100) / 100}</TableCell> */}
              {/* <TableCell align="right">{Math.round(((value.dreb / value.gp) + Number.EPSILON) * 100) / 100}</TableCell> */}
              <TableCell align="right">{Math.round(((value.trb / value.gp) + Number.EPSILON) * 100) / 100}</TableCell> 
              <TableCell align="right">{Math.round(((value.ast / value.gp) + Number.EPSILON) * 100) / 100}</TableCell>
              <TableCell align="right" className='mobileHide'>{Math.round(((value.stl / value.gp) + Number.EPSILON) * 100) / 100}</TableCell>
              <TableCell align="right" className='mobileHide'>{Math.round(((value.blk / value.gp) + Number.EPSILON) * 100) / 100}</TableCell>
              {/* <TableCell align="right">{Math.round(((value.tov / value.gp) + Number.EPSILON) * 100) / 100}</TableCell> */}
              <TableCell align="right">{Math.round(((value.pts / value.gp) + Number.EPSILON) * 100) / 100}</TableCell> 
            </TableRow>
          // ))}
          ) )}
        </TableBody>
      </Table>
    </TableContainer>
    </>
  );
}

export default BasicTable;