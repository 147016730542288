import nba from '../imgs/nba.png';
// import nfl from '../imgs/nfl.png';
// import mlb from '../imgs/mlb.png';
// import ufc from '../imgs/UFC.png';
import ResponsiveAppBar from '../components/ResponsiveAppBar';
import { Link } from "react-router-dom";
import '../App.css';
const Home = () => {
  // const answerID = 0;
  // const answerName = '';
  // const answerNBAAPIID = 0;
  return (
        <div className="App">
          <header className="App-header">
          <ResponsiveAppBar />

            <h1>Sportguesser</h1>
            <h2>Pick a Category</h2>
            {/* <h3>Next Sportle in: timer</h3> */}
          <div className='selectionContainer'>
            <div className='imageLabels'>
           
            <Link to="NBA" >
               <img className='formImg' src={nba} alt="NBA"/>
            </Link>
            </div>
            {/* <div className='imageLabels'> 
            <Link to="nfl" >
              <img className='formImg' src={nfl} alt="NFL"/>
              </Link>
            </div> */}
            {/* <div className='imageLabels'>
            <Link to="mlb" >
              <img className='formImg' src={mlb} alt="MLB"/>
              </Link>
            </div> */}
            {/* <div className='imageLabels'>
            <Link to="ufc" >
              <img className='formImg' src={ufc} alt="UFC"/>
              </Link>
            </div> */}
            {/* 
            <div className='imageLabels'>
            <Link to="MOVIES" >
              
              Movies
              </Link> 
            </div>*/}
            </div>
      
           
          </header>
        </div>
      );
};

export default Home;