
import { useState, useEffect } from 'react';
import TextField from '@mui/material/TextField';
import Box from "@mui/material/Box";
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';
import CircularProgress from '@mui/material/CircularProgress';
// import Autocomplete, { createFilterOptions } from '@material-ui/lab/Autocomplete';
import Button from '@mui/material/Button';
import '../App.css';
import BasicTable from "../components/NBATable";
import PlayerInfo from "../components/NBAPlayerInfo";
import ResponsiveAppBar from '../components/ResponsiveAppBar';
import { useDispatch, useSelector } from "react-redux";
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import GuessDistributionModal from '../components/GuessDistributionModal';
// import { setData, setLoading, setError } from "../app/reducers/actions";
// import jsonData from './players.json';
import { fetchData, postRecordedGuess, getRecordedGuesses } from "../app/reducers/actions";
import Papa from 'papaparse';
import nbaBackgroundImage from '../imgs/nba-sportguesser-background.jpg';


// console.log('json loadData');
// console.log(jsonData);
// var arr2 = [];
// Object.keys(jsonData).forEach(key => arr2.push({ id: jsonData[key].ID, name: jsonData[key].fullName }))
// console.log('array json');
// console.log(arr2);
// const len = jsonData.length;
// const randomID = jsonData[Math.floor(Math.random() * len)];
// console.log('array id');
// console.log(randomID);
// const activePlayer =  randomID;
// const answerID = randomID.ID;
// const answerName = randomID.fullName;
// const data = jsonData;


const NBA = () => {

  
  const dispatch = useDispatch();
  // const { data, answerID, answerNBAAPIID, answerName, loading, error } = useSelector(
  const { data } = useSelector(
    (state) => state.nbaData
  );
  const nbaAreaStyle = {
    backgroundImage: `url(${nbaBackgroundImage})`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    // Add other background-related styles if needed
  };
  const [score, setScore] = useState(() => {
    // Check if the variable exists in localStorage
    const storedScore = localStorage.getItem('score');
    return storedScore ? parseInt(storedScore) : 0;
  });
  const [highScore, setHighScore] = useState(() => {
    // Check if the variable exists in localStorage
    const storedHighScore = localStorage.getItem('highScore');
    return storedHighScore ? parseInt(storedHighScore) : 0;
  });

  useEffect(() => {
    dispatch(fetchData());
  }, [dispatch]);

  const [answerID, setAnswerID] = useState(0);
  const [answerNBAAPIID, setAnswerNBAAPIID] = useState(0);
  const [answerName, setAnswerName] = useState('');
  const [sortedData, setSortedData] = useState([]);
  useEffect(() => {
    if (data.length > 0) {
      const sorted = [...data].sort((a, b) =>
        a.fullName.localeCompare(b.fullName)
      );

      setSortedData(sorted);

      const len = data.length;
      let findRandomID = null;
      while (!findRandomID || findRandomID.nba_api_player_id === null) {
        findRandomID = data[Math.floor(Math.random() * len)];
      }
      
      setAnswerID(findRandomID.player_id);
      // const randomIndex = Math.floor(Math.random() * data.length);
      // const randomId = data[randomIndex].id;
      // Use the randomId as desired
      if (findRandomID && findRandomID.nba_api_player_id !== null) {
        // The randomID has a valid non-null nba_api_player_id
        // console.log('Found a valid player ID:', randomID.nba_api_player_id);
      } else {
        console.log('Unable to find a player with a valid player ID');
      }
      setAnswerNBAAPIID(findRandomID.nba_api_player_id);
      setAnswerName(findRandomID.fullName);
  
    }
  }, [data]);


  // console.log(answerName);

  useEffect(() => {
    // Update the stored score whenever it changes
    localStorage.setItem('score', score.toString());
  }, [score]);
  useEffect(() => {
    // Update the stored highScore whenever it changes
    localStorage.setItem('highScore', highScore.toString());
  }, [highScore]);
  // const { data, answerID, answerNBAAPIID, answerName, activePlayer, loading, error } = useFetch("/nbaplayers");

  // console.log('data from mongo');
  // console.log(data);

  //  const len2 = data.length;
  //  const randomID2 = data[Math.floor(Math.random() * len2)];
    //  console.log('array id2');
    //  console.log(answerNBAAPIID);

//    const activePlayer2 =  randomID2;
//  const answerID2 = randomID2.ID;
//  const answerName2 = randomID2.fullName;


  // const [data, setdata] = useState({'id':'001', 'name': 'Player Name'});
  // const [activePlayer, setActivePlayer] = useState(0);
  // const [answerID, setAnswerID] = useState(0);
  // const [answerName, setAnswerName] = useState(0);
  // const loadData = () => JSON.stringify(data);




// let numOfGuesses = 5;


// Using useEffect for single rendering



// end testing api

  const [numOfGuesses, setNumOfGuesses] = useState(5);
  const [guessPlayer, setGuessPlayer] = useState({});
  const [guesses, setGuesses] = useState([]);
  const [disabled, setDisabled] = useState(false);
  const [showAnswer, setShowAnswer] = useState(false);
  const [showRightWrong, setShowRightWrong] = useState(false);
  const [open, setOpen] = useState(false);
  const [showGuessDistribution, setShowGuessDistribution] = useState(false);

  
  // const listbox = {
  //   data: ['Peach', 'Pear', 'Pineapple', 'Plum', 'Pomegranate', 'Prune']
  // }
//  console.log(guesses);
const handleOpen = () => {
  setOpen(true);
};

const handleClose = () => {
  setOpen(false);
};



const submit = async (e) => {
  e.preventDefault();
  setNumOfGuesses(numOfGuesses - 1);

  // ... other logic

  if (guessPlayer.ID === answerID) {
    const actualNumOfGuesses = 5 - numOfGuesses;
    try {
      // Dispatch the postRecordedGuess action
      await dispatch(postRecordedGuess(answerID, actualNumOfGuesses));
    } catch (error) {
      console.error('Error writing to recordedGuesses table:', error);
    }
    setShowRightWrong(true);
    setDisabled(true);
    setNumOfGuesses(0);
    setScore((prevScore) => prevScore + 1);
    // Write to recordedGuesses table
    setShowGuessDistribution(true);
  }

  if (numOfGuesses === 1) {
    try {
      // Dispatch the postRecordedGuess action
      await dispatch(postRecordedGuess(answerID, 6));
    } catch (error) {
      console.error('Error writing to recordedGuesses table:', error);
    }
    setDisabled(true);
    setShowAnswer(true);
    setShowGuessDistribution(true);
    // Write to recordedGuesses table with numOfGuesses as 5
    

    // ... other logic
  }

  setGuessPlayer('');
};

  const resetGuesses = async () => {
    try {
      // Dispatch the postRecordedGuess action
      await dispatch(postRecordedGuess(answerID, 6));
    } catch (error) {
      console.error('Error writing to recordedGuesses table:', error);
    }
    setNumOfGuesses(0);
    setDisabled(true);
    setShowAnswer(true);
    if (score > highScore) {
      setHighScore(score);
    }

    setScore(0);
    handleClose();
    setShowGuessDistribution(true);
  };


  const handleChange = (event, value) => {
    // console.log(value);
 

    if (value !== undefined && value !== null) {
      setGuessPlayer(value);
    // console.log(guessPlayer);

    }
  }
  const refreshPage = () => {
    setNumOfGuesses(5);
    setDisabled(false);
    setShowAnswer(false);
    setShowRightWrong(false);
    setGuesses([]);
    setAnswerID(0);
    setAnswerNBAAPIID(0);
    setAnswerName('');
    
      if (data.length > 0) {
        const len = data.length;
        let findRandomID = null;
        while (!findRandomID || findRandomID.nba_api_player_id === null) {
          findRandomID = data[Math.floor(Math.random() * len)];
        }
        
        if (findRandomID && findRandomID.nba_api_player_id !== null) {
          setAnswerID(findRandomID.player_id);
          setAnswerNBAAPIID(findRandomID.nba_api_player_id);
          setAnswerName(findRandomID.fullName);
        } else {
          console.log('Unable to find a player with a valid player ID');
        }
      }

  };
  useEffect(() => {
    // Perform any actions or side effects that should occur when answerID changes
    // This code will run whenever answerID changes
    
    // console.log('answerID has changed:', answerID);
    // console.log('answerName has changed:', answerName);
    // Add your code to update other elements of the page here
  }, [answerID, answerName, answerNBAAPIID]);
  
  // console.log('answerID');
  // console.log(answerID);
  // console.log('numOfGuesses');
  // console.log(numOfGuesses);
  // to speed up autocomplere
  const filterOptions = createFilterOptions({
    matchFrom: 'any',
    limit: 50,
  });

  const [options, setOptions] = useState([]);
  useEffect(() => {
    async function fetchData() {
      try {
        const response = await fetch('/All-Players-Ever-ID.csv');
        const text = await response.text();
    
        // Split the CSV text into an array of lines
        const lines = text.split('\n');
    
        // Parse the CSV data into an array of objects
        const parsedData = [];
    
        for (let index = 0; index < lines.length; index++) {
          const line = lines[index];
          const [playerId, fullName] = line.split(',');
          // console.log(playerId);
          // console.log(fullName);
          // Check if playerId and fullName exist before using trim and replace
          const trimmedId = playerId ? parseInt(playerId.trim()) : null; // Convert playerId to a number
          const trimmedFullName = fullName ? fullName.trim().replace(/\r/g, '') : '';
    
          parsedData.push({
            ID: trimmedId,
            fullName: trimmedFullName,
          });
        }
    
        setOptions(parsedData);
      } catch (error) {
        console.error('Error fetching or parsing CSV data:', error);
      }
    }

    fetchData();
  }, []);
  // console.log(options);
// console.log('Guesses');
// console.log(guesses);
// console.log(activePlayer);
  return (
    <div className="App" style={nbaAreaStyle}>
      <header className="App-header2">
        <ResponsiveAppBar />
        {/* <div className="TopHeader">
        <Link to="/" className = 'SportHome'><h1 className="selectedPageTitle">Sportle</h1></Link>
        </div> */}
        {/* <div className='nbaArea' > */}
        <h2 className="NBATITLE">NBA - Guess the Player</h2>
       <span className="scoreBoard">Your High Score: {highScore} | Score: {score}</span>
    
  {/* {answerNBAAPIID >= 0 ? <PlayerInfo parentToChild={answerNBAAPIID} numGuesses={numOfGuesses}/> : <Box className="paddingBox" sx={{ display: 'flex' }}><CircularProgress /></Box>} */}
  {answerNBAAPIID > 0 && answerName !== '' && answerID > 0 ? (
        <PlayerInfo parentToChild={answerNBAAPIID} otherId={answerID} fullNameProp={answerName} numGuesses={numOfGuesses} />
      ) : (
        <Box className="paddingBox" sx={{ display: "flex" }}>
          <CircularProgress />
        </Box>
      )}
      {answerID > 0 ? (
        <BasicTable parentToChild={answerID} numGuesses={numOfGuesses} />
      ) : (
        <Box className="paddingBox" sx={{ display: "flex" }}>
          <CircularProgress />
        </Box>
      )}
  {/* {answerID !== 0 ? <BasicTable parentToChild={answerID} numGuesses={numOfGuesses}/> :  <Box className="paddingBox" sx={{ display: 'flex' }}><CircularProgress /></Box>} */}
             
    {/* <BasicTable parentToChild={answerID}/> */}
        
        <h3 className={showRightWrong ? "showAnswer" : "hideAnswer"}>Congratulations! You got it</h3>
        <h3 className={!showRightWrong ? "showAnswer" : "hideAnswer"}>Guesses Remaining: {numOfGuesses > 0 ? numOfGuesses : 'Game Over!'}</h3>
        {/* <h3>Guess</h3> */}
        <form onSubmit={submit}>
      {/* <input
        type="text"
        id="guessPlayer"
        name="guessPlayer"
        required
        value={guessPlayer}
        placeholder="Guess Player"
        disabled={disabled}
        onChange={e => setGuessPlayer(e.target.value)}
      /> */}
    
<div className="acoutcompleteHolder">
<Autocomplete
      disablePortal
      id="guessPlayer2"
      name="guessPlayer"
      value={guessPlayer}
      filterOptions={filterOptions}
      disabled={disabled}
      options={options}
      getOptionLabel={(option) => option.fullName ? option.fullName : ""}
      sx={{ width: 300 }}
  
      isOptionEqualToValue={(option, value) =>
        option.ID === value.ID && option.fullName === value.fullName
      }
      // onChange={(event, value) => setGuessPlayer(event.target.value)}
      onChange={handleChange}
      // onChange={(event, value) => console.log(value.label)}
      renderOption={(props, option) => (
        <Box component="li" {...props} key={option.ID}>
          {option.fullName} 
        </Box>
      )}
      renderInput={(params) => <TextField {...params} label="Guess Player"
      // getOptionSelected={(option) => option.title === 'dull'}
      
      // onChange={e => setGuessPlayer(e.target.value)}
      />}
    />
    </div>
    
      <button disabled={!guessPlayer.fullName} className={!disabled ? "showButton" : "hideButton"} onClick={() => {
        setGuesses([
          ...guesses,
          { id: guessPlayer.ID, guess: guessPlayer.fullName }
        ]);
      }}>Guess</button><br></br>
{/* <button disabled={disabled} className="otherButtons redButton" onClick={resetGuesses}>Give Up?</button> */}
      {/* <br /> */}

   
    </form>

    <div>
      <Button disabled={disabled} className="otherButtons redButton" variant="contained"  onClick={handleOpen}>
        Give up?
      </Button>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="give-up-modal"
        aria-describedby="give-up-modal-description"
      >
        <Fade in={open}>
          <div
            style={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              backgroundColor: '#fff',
              padding: '2rem',
              outline: 'none',
              borderRadius: '8px',
            }}
          >
            <h2 id="give-up-modal">Are you sure?</h2>
            <p id="give-up-modal-description">
              Do you really want to give up?
            </p>
            <Button variant="contained" className='redButton' onClick={resetGuesses}>
              Give Up
            </Button>
            <Button variant="contained" className='greyButton' onClick={handleClose}>
              Cancel
            </Button>
          </div>
        </Fade>
      </Modal>
    </div>

    <ul className="guessList">
        {guesses.map(guesses => (
           <>
          {/* <script>console.log(guesses.id);</script> */}
          <li key={guesses.id}>{guesses.guess} {guesses.id === answerID ? '✅': '❌'} </li>
          </>
        ))}
      </ul> 
      <p className={showRightWrong ? "showAnswer" : "hideAnswer"}>Correct Answer </p>
      <p className={showAnswer ? "showAnswer" : "hideAnswer"}>Answer: {answerName}</p>
      <button className={showRightWrong || showAnswer ? "showAnswer otherButtons blueButton" : "hideAnswer otherButtons blueButton"} onClick={refreshPage}>Play Again?</button>
      {/* <Button variant="contained">Need a hint?</Button> */}
      {showGuessDistribution && (
        <GuessDistributionModal answerID={answerID} />
      )}
      {/* </div> */}
      </header>
    </div>
  );
};

export default NBA;