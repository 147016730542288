const initialState = {
  data: [],
  statData: [],
  playerData: [],
  answerID: 0,
  answerNBAAPIID: 0,
  answerName: '',
  activePlayer: [],
  loading: false,
  error: false,
};

export default function nbaDataReducer(state = initialState, action) {
  switch (action.type) {
    case "SET_DATA":
      return {
        ...state,
        data: action.payload.data,
      };
      case "SET_STATDATA":
    return {
        ...state,
        statData: action.payload.data,
      };
    case "SET_PLAYERDATA":
        return {
          ...state,
          playerData: action.payload.data,
        }; 
    case "SET_ANSWERID":
      return {
        ...state,
        answerID: action.payload.player_id,
      };
    case "SET_ANSWERNBAAPIID":
      return {
        ...state,
        answerNBAAPIID: action.payload.nba_api_player_id,
      };
    case "SET_ANSWERNAME":
      return {
        ...state,
        answerName: action.payload.fullName,
      };
    case "SET_LOADING":
      return {
        ...state,
        loading: action.payload,
      };
    case "SET_ERROR":
      return {
        ...state,
        error: action.payload,
      };
    default:
      return state;
  }
}
