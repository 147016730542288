import * as React from 'react';
import { useState, useEffect } from 'react';
// import useFetch from "../hooks/useFetch2";
// import { format } from 'date-fns';
import { useDispatch, useSelector } from "react-redux";
import { fetchPlayerInfoData } from "../app/reducers/actions";
import '../App.css';
import noImage from '../imgs/noimg.png';

const PlayerInfo = (props) => {


  //   console.log('in nba player info');
  // console.log(props);
  const dispatch = useDispatch();
const { playerData, loading, error } = useSelector(
  (state) => state.nbaPlayerInfoData
);
// console.log(props);
useEffect(() => {
  dispatch(fetchPlayerInfoData(`https://ancient-peak-10946.herokuapp.com/api/nbaplayerinfo/${props.parentToChild}`));
}, [dispatch, props.parentToChild]);

// const [imageSrc2, setImageSrc2] = useState('https://cdn.nba.com/headshots/nba/latest/260x190/fallback.png');
const [imageSrc2, setImageSrc2] = useState();

useEffect(() => {
  const img = new Image();
  img.src = `https://cdn.nba.com/headshots/nba/latest/260x190/${props.parentToChild}.png`;

  img.onload = () => {
    // console.log('Image loaded successfully:', img.src);
    setImageSrc2(img.src);
  };

  img.onerror = () => {
    console.error('Image failed to load. Switching to fallback image.');
    setImageSrc2('https://cdn.nba.com/headshots/nba/latest/260x190/fallback.png');
  };
}, [props.parentToChild]);

  // could delete 
// const [imageSrc, setImageSrc] = useState(noImage);
//   // could delete 
//   useEffect(() => {
//     import(`../imgs/headshots/${props.otherId}.jpg`)
//     // import(`../imgs/headshots/${props.parentToChild}.png`)
//       .then((module) => {
//         setImageSrc(module.default);
//       })
//       .catch((error) => {
//         console.error('Error loading the image:', error);
//       });
//   }, [props.otherId]);



  // could delete 
  // const handleError = () => {
  //   setImageSrc('../imgs/noImage.png');
  // };
 
  // const [arr, setarr] = useState({});
  // console.log('in nba player info');
  // console.log(props);

  // const { data, loading, error } = useFetch(`/nbaplayerinfo/${props.parentToChild}`);
  // console.log('array 2 get player info ');
  // console.log(data);

// useEffect(() => {
//   var apl = [];
//   // Using fetch to fetch the api from 
//   // flask server it will be redirected to proxy
//   // fetch("/data").then((res) =>
//   //     res.json().then((data) => {
//   //         // Setting a data from api
//   //         console.log(data);
//   //         setdata({
//   //             name: data.key.full_name,
//   //         });
//   //     })
//   // );

//   fetch(`/getPlayerInfo${props.parentToChild}`)
//       .then((response) => response.json())
//       .then((actualData) => {
//         console.log('actual player data');
//         console.log(actualData);
        
//         Object.keys(actualData.CommonPlayerInfo).forEach(key => apl.push( actualData.CommonPlayerInfo[key] ))
        
//         setarr(apl);

//         // setdata({
//         //   full_name: actualData[0].full_name,
//         //   id: actualData[0].id,
//         // });

//       })
//       .catch((err) => {
//         console.log(err.message);
//       });
// }, []);


const [firstName, lastName] = props.fullNameProp.split(' ');
// const initials = `${firstName[0]}${'-'.repeat(firstName.length - 1)} ${lastName[0]}${'-'.repeat(lastName.length - 1)}`;
const initialFirst = `${firstName[0]}`;
const initialLast = `${lastName[0]}`;

  if (loading) {
    return <div>Loading...</div>; // Display a loading indicator while data is being fetched
  }

  if (error) {
    return <div>Error occurred. Please try again later.</div>; // Display an error message if an error occurred
  }
  return (
  //  <div className='playerHelpInfo'>
  //  {Object.values(arr).map((value, index) => 
  //         // {arr.map((year) => (
  //           <div key={index} className='playerHelpInfo'>
  //           <p>Country: <span className={props.numGuesses >= 5 ? "blurredText" : ""}>{value.COUNTRY}</span></p>
  //           <p>School: <span  className={props.numGuesses > 4 ? "blurredText" : ""}>{value.SCHOOL}</span></p>
  //           <p>Position: <span  className={props.numGuesses > 3 ? "blurredText" : ""}>{value.POSITION}</span></p>
  //           {/* <p>Birthdate: {format(value.BIRTHDATE, 'yyyy/mm/dd')}</p> */}
  //           <p>Height: <span  className={props.numGuesses > 2 ? "blurredText" : ""}>{value.HEIGHT}</span></p>
  //           <p>Weight: <span  className={props.numGuesses > 1  ? "blurredText" : ""}>{value.WEIGHT}</span></p>
  //         </div>
  //         )}
  //  </div>
  <>
 <img
      src={imageSrc2}
      alt="Player Headshot"
      className={props.numGuesses > 1 ? "noShowImg" : "showImg"}
    />

      {/* <img src={imageSrc} onError={handleError} className={props.numGuesses > 1 ? "noShowImg" : "showImg"} alt="Player Headshot" /> */}
 
   <div className='playerHelpInfo'>

     

            <div className='playerHelpInfo'>
            <p>Country: <span className={props.numGuesses >= 5 ? "blurredTextNBA" : ""}>{playerData.COUNTRY}</span></p>
            <p>School: <span  className={props.numGuesses > 4 ? "blurredTextNBA" : ""}>{playerData.SCHOOL}</span></p>
            {playerData.DRAFT_ROUND && (
        <p>
          Draft Round: <span className={props.numGuesses > 3 ? 'blurredTextNBA' : ''}>{playerData.DRAFT_ROUND}</span>
        </p>
      )}
            {/* <p>Draft Round: <span  className={props.numGuesses > 3 ? "blurredTextNBA" : ""}>{playerData.DRAFT_ROUND}</span></p> */}
            {playerData.DRAFT_NUMBER && (
        <p>
          Draft Number: <span className={props.numGuesses > 3 ? 'blurredTextNBA' : ''}>{playerData.DRAFT_NUMBER}</span>
        </p>
      )}
            {/* <p>Draft Number: <span  className={props.numGuesses > 2 ? "blurredTextNBA" : ""}>{playerData.DRAFT_NUMBER}</span></p> */}
    
            

            <p>Height: <span  className={props.numGuesses > 2 ? "blurredTextNBA" : ""}>{playerData.HEIGHT}</span></p>
            {/* <p>Weight: <span  className={props.numGuesses > 1  ? "blurredTextNBA" : ""}>{playerData.WEIGHT}</span></p> */}
            {/* <p className={props.numGuesses > 1 ? "noShow" : "showText"}>Name: <span  className={props.numGuesses > 1  ? "blurredTextNBA" : ""}>{initials}</span></p> */}
            {/* <p className={props.numGuesses > 3 ? "noShow" : "showText"}>First Name Initial: <span  className={props.numGuesses > 3  ? "blurredTextNBA" : ""}>{initialFirst}</span></p> */}
            {/* <p className={props.numGuesses > 1 ? "noShow" : "showText"}>Last Name Initial: <span  className={props.numGuesses > 1  ? "blurredTextNBA" : ""}>{initialLast}</span></p> */}
          </div>

   </div>
   </>
  );
}

export default PlayerInfo;