// export const sortNBAData = (data) => {
//   const sortedData = [...data]; // Create a copy to avoid modifying the original data

//   sortedData.sort((a, b) => {
//     // Sort by season as numbers (e.g., "1994" to 1994)
//     const seasonA = parseInt(a.Season);
//     const seasonB = parseInt(b.Season);

//     if (seasonA !== seasonB) {
//       return seasonA - seasonB;
//     }

//     // Sort by team abbreviation (TM)
//     const teamComparison = (a.TM || '').localeCompare(b.TM || '');

//     if (teamComparison !== 0) {
//       return teamComparison;
//     }

//     return 0;
//   });

//   return sortedData;
// };
// Function to sort player data
// Function to sort player data
// Function to sort player data
// Function to sort player data
// Sorting function
// Function to sort player data
const sortNBAData = (data) => {
  const sortedData = [...data];

  sortedData.sort((a, b) => {
    // Compare seasons first in ascending order
    if (a.season > b.season) return 1;
    if (a.season < b.season) return -1;

    // If the same season, compare team abbreviations within the same season
    if (a.season === b.season) {
      // Check if either entry is TOT
      const isATOT = a.team_abbreviation === 'TOT';
      const isBTOT = b.team_abbreviation === 'TOT';

      // If both are TOT, no change in order
      if (isATOT && isBTOT) return 0;

      // If only A is TOT, push it to the end
      if (isATOT) return 1;

      // If only B is TOT, push it to the end
      if (isBTOT) return -1;

      // Compare based on previous_team
      if (a.previous_team === b.team_abbreviation) return -1;
      if (b.previous_team === a.team_abbreviation) return 1;
    }

    return 0; // Default case, no change in order
  });

  return sortedData;
};

export { sortNBAData };


















