// import { configureStore, combineReducers } from '@reduxjs/toolkit'
// import rootReducer from "./reducers";
// import nbaDataReducer from "./reducers/nbaDataReducer";

// rootReducer = combineReducers({
//   nbaData: nbaDataReducer,
// });

// export default configureStore({
//   reducer: {rootReducer},
// })

import { configureStore } from '@reduxjs/toolkit';
import rootReducer from "./reducers";




export default configureStore({
  reducer: rootReducer,
})
// import { createStore } from "redux";
// import rootReducer from "./reducers";

// const store = createStore(rootReducer);

