// guessesReducer.js

// Define the initial state for the guesses
const initialState = {
  guesses: [],
};

// Define the reducer function
export default function guessesReducer(state = initialState, action) {
  switch (action.type) {
    case 'ADD_GUESS':
      return {
        ...state,
        guesses: [...state.guesses, action.payload],
      };
    case 'RESET_GUESSES':
      return {
        ...state,
        guesses: [],
      };
    default:
      return state;
  }
}