const initialState = {
  playerData: [],
  loading: false,
  error: false,
};

export default function nbaPlayerInfoDataReducer(state = initialState, action) {
  switch (action.type) {
    case "SET_PLAYERDATA":
      return {
        ...state,
        playerData: action.payload.data,
      };
    default:
      return state;
  }
}
